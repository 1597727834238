<template>
<div id="app">
<div id="sf-header" class="header-overlap">
    <div data-animation="default" class="header w-nav" data-easing2="ease" data-easing="ease" data-collapse="medium" data-w-id="05ff44c0-99a6-9494-15ae-275fa856e344" role="banner" data-duration="100" data-doc-height="1">
      <div class="header-wrapper">
        <router-link class="text-uppercase font-weight-bold mr-auto text-dark" :to="{ name: 'home', params: {} }"><img src="img/surefooted-logo-full-white.svg" loading="lazy" alt="" class="logo-embed"></router-link>
        <div data-w-id="253cd9aa-6ab4-19d1-7bf4-0efa776225f2" class="menu">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <router-link class="navigation-item white purple w-nav-link" :to="{ name: 'login', params: {} }">Log in</router-link>
            <router-link :to="{ name: 'signup', params: {} }"><div class="button transparent header-button w-inline-block text-block">Sign up</div></router-link>
          </nav>
          <div class="menu-button white w-nav-button">
            <div data-w-id="253cd9aa-6ab4-19d1-7bf4-0efa776225fd" class="w-embed">
              <svg version="1.1" baseprofile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="27.9px" height="22.1px" viewbox="0 0 27.9 22.1" overflow="visible" xml:space="preserve">
                <g fill="currentColor">
                  <path d="M1.2,2.5h25.4c0.7,0,1.2-0.6,1.2-1.2S27.3,0,26.6,0H1.2C0.6,0,0,0.6,0,1.2S0.6,2.5,1.2,2.5z"></path>
                  <path d="M26.6,9.8H1.2C0.6,9.8,0,10.3,0,11s0.6,1.2,1.2,1.2h25.4c0.7,0,1.2-0.6,1.2-1.2S27.3,9.8,26.6,9.8z"></path>
                  <path d="M26.6,19.6H1.2c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h25.4c0.7,0,1.2-0.6,1.2-1.2S27.3,19.6,26.6,19.6z"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section hero wf-section">
    <div data-w-id="ebce54ba-0f27-ec32-a2d9-77c9ab1d3004" style="opacity:1" class="hero-topo"></div>
    <div class="hero-wrapper">
      <div class="container w-container">
        <div class="w-layout-grid grid-2-col hero">
          <div data-w-id="71a1e384-fdbb-f0b9-b424-b4378284191c" style="opacity:1" class="text-align-centre-mobile">
            <h1 class="white">Say hello to live booking and availability</h1>
            <p class="para-big white">The B2B platform connecting activity holiday operators and travel agents. Expand your customer base, organise holiday data and take live bookings, all in one place.</p>
            <router-link :to="{ name: 'signup', params: {} }"><div class="button transparent w-inline-block">Join for free</div></router-link>
          </div>
          <div id="w-node-_7959e821-cc2c-7dbf-058d-c7821988e98e-7a2221ec" class="hero-image-wrapper"><img class="home-hero-image" src="img/surefooted-screenshot.webp" width="593" alt="" style="opacity:1" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 500px, 593px" data-w-id="dc81dc34-18c6-4eaa-571a-83460c573bbf" loading="lazy" srcset="img/surefooted-screenshot-p-500.png 500w, img/surefooted-screenshot-p-800.png 800w, img/surefooted-screenshot-p-1080.png 1080w, img/surefooted-screenshot.webp 1235w"></div>
        </div>
      </div>
    </div>
    <div class="curve-bottom-hero-wrap"><img src="img/curve-bottom-hero.svg" loading="lazy" alt="" class="curve-bottom-hero"></div>
  </div>
  <div class="section testimonial section-2 wf-section">
    <div class="container skinny w-container">
      <div data-w-id="91399895-abd5-b8dd-4158-be616a1f9d98" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="w-layout-grid grid-2-col-1fr-x-2fr"><img src="img/testimonial-placeholder.webp" loading="lazy" width="171" id="w-node-_91399895-abd5-b8dd-4158-be616a1f9d99-7a2221ec" alt="" class="image-circle testimonial-photo">
        <div id="w-node-_91399895-abd5-b8dd-4158-be616a1f9d9a-7a2221ec" class="text-align-centre-mobile">
          <p class="testimonial">&quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.&quot; </p>
          <p class="testimonial-creds container">Name, Company</p>
        </div>
      </div>
    </div>
  </div>
  <div class="section margin-tb-60 wf-section">
    <div class="container slim w-container" style="background-color: white;">
      <div data-w-id="d2cf8a1e-6987-83bd-7b38-9c9851f131d3" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform-style: preserve-3d;" class="w-layout-grid grid-2-col-2fr-x-3fr">
        <div id="w-node-d2cf8a1e-6987-83bd-7b38-9c9851f131d4-7a2221ec" class="flex-vertical centre text-align-centre-mobile">
          <h2>Live bookings and availability, finally!</h2>
          <p>Add your holidays to Surefooted, then embed them on your website or anywhere across the web to take live bookings from customers. Availability is updated everywhere - automatically.</p>
          <router-link :to="{ name: 'signup', params: {} }"><div class="button-text-only w-inline-block">Sign up for free</div></router-link>
            <div class="button-icon">
              <div class="icon margin-lr-auto w-embed">
                <!--  Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)   -->
                <!-- <svg version="1.1" id="icon_arrow_right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve"> -->
                 <!--  <style type="text/css">
.icon_arrow_right{fill:currentColor;}
</style> -->
                <!--   <path class="icon_arrow_right" d="M14.7,6.9l-3.4-3.4c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l2.3,2.3H0.7C0.3,6.8,0,7.1,0,7.5v0
c0,0.4,0.3,0.7,0.7,0.7h11.9l-2.3,2.3c-0.3,0.3-0.3,0.7,0,1l0,0c0.3,0.3,0.7,0.3,1,0l3.4-3.3C15.1,7.8,15.1,7.3,14.7,6.9z"></path>
                </svg> -->
              </div>
            </div>
        </div>
        <div class="flex-right"><img src="img/home-live-bookings-placeholder.webp" loading="lazy" width="524" srcset="img/home-live-bookings-placeholder-p-500.png 500w, img/home-live-bookings-placeholder-p-800.png 800w, img/home-live-bookings-placeholder.webp 1007w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 83vw, 524px" alt="" class="flex-right"></div>
      </div>
    </div>
  </div>
  <div class="section wf-section">
    <div class="container slim w-container" style="background-color: white;">
      <div data-w-id="44cd175c-7509-3b60-5a1c-08a099564356" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="w-layout-grid grid-2-col-3fr-x-2fr">
        <div class="flex-left"><img src="img/surefooted-connecting.webp" loading="lazy" width="502" sizes="(max-width: 479px) 100vw, (max-width: 767px) 83vw, 502px" srcset="img/surefooted-connecting-p-500.png 500w, img/surefooted-connecting-p-800.png 800w" alt=""></div>
        <div id="w-node-_44cd175c-7509-3b60-5a1c-08a099564359-7a2221ec" class="flex-vertical centre text-align-centre-mobile">
          <h2>Connecting Operators and Agents</h2>
          <p>Reach more customers, increase your booking potential and maximise your sales. Operators and Agents can filter and search other users to request and grant access to holiday Operators’ listings.</p>
           <router-link :to="{ name: 'login', params: {} }"><div class="button-text-only w-inline-block">Start networking</div></router-link>
            <div class="button-icon">
              <div class="icon margin-lr-auto w-embed">
                <!--  Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)   -->
               <!--  <svg version="1.1" id="icon_arrow_right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
                 <style type="text/css">
.icon_arrow_right{fill:currentColor;}
</style>
                  <path class="icon_arrow_right" d="M14.7,6.9l-3.4-3.4c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l2.3,2.3H0.7C0.3,6.8,0,7.1,0,7.5v0
c0,0.4,0.3,0.7,0.7,0.7h11.9l-2.3,2.3c-0.3,0.3-0.3,0.7,0,1l0,0c0.3,0.3,0.7,0.3,1,0l3.4-3.3C15.1,7.8,15.1,7.3,14.7,6.9z"></path>
                </svg> -->
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section wf-section">
    <div data-w-id="44714af4-0752-40d2-8bc3-d8dd23ff5204" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="container skinny text-align-centre pad-lr-60 w-container">
      <h2>Streamline your activity holiday data</h2>
      <p>No more emails, phone calls, crossed wires, and unreliable booking trackers. Surefooted is your new place to manage all your activity holiday listings, whether you’re an Operator or an Agent. Input dates and pricing, embed holidays, take live bookings and check availability with just a few clicks.</p>
      <div class="spacer _60"></div>
    </div>
    <div class="container slim w-container" style="background-color: white;">
      <div data-duration-in="300" data-duration-out="100" data-w-id="8cfddff7-a59e-0740-2ce2-499ecbd4d2f9" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" data-current="Tab 1" data-easing="ease" class="w-tabs">
        <div class="tabs-menu w-tab-menu">
          <a data-w-tab="Tab 1" class="tab w-inline-block w-tab-link w--current">
            <div>One source of the truth</div>
          </a>
          <a data-w-tab="Tab 2" class="tab hidden w-inline-block w-tab-link">
            <div>Flexible booking dates</div>
          </a>
          <a data-w-tab="Tab 3" class="tab last w-inline-block w-tab-link">
            <div>Flexible booking dates</div>
          </a>
        </div>
        <div class="tabs-content w-tab-content">
          <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active"><img sizes="(max-width: 479px) 0px, 100vw" srcset="img/tab-placeholder-p-500.png 500w, img/tab-placeholder-p-800.png 800w, img/tab-placeholder-p-1080.png 1080w, img/tab-placeholder-p-1600.png 1600w, img/tab-placeholder-p-2000.png 2000w, img/tab-placeholder-p-2600.png 2600w, img/tab-placeholder.webp 3182w" loading="lazy" src="img/tab-placeholder.webp" alt=""></div>
          <div data-w-tab="Tab 2" class="w-tab-pane"><img sizes="(max-width: 479px) 0px, 100vw" srcset="img/tab-placeholder-p-500.png 500w, img/tab-placeholder-p-800.png 800w, img/tab-placeholder-p-1080.png 1080w, img/tab-placeholder-p-1600.png 1600w, img/tab-placeholder-p-2000.png 2000w, img/tab-placeholder-p-2600.png 2600w, img/tab-placeholder.webp 3182w" loading="lazy" src="img/tab-placeholder.webp" alt=""></div>
          <div data-w-tab="Tab 3" class="w-tab-pane"><img sizes="(max-width: 479px) 0px, 100vw" srcset="img/tab-placeholder-p-500.png 500w, img/tab-placeholder-p-800.png 800w, img/tab-placeholder-p-1080.png 1080w, img/tab-placeholder-p-1600.png 1600w, img/tab-placeholder-p-2000.png 2000w, img/tab-placeholder-p-2600.png 2600w, img/tab-placeholder.webp 3182w" loading="lazy" src="img/tab-placeholder.webp" alt=""></div>
        </div>
      </div>
    </div>
  </div>
  <div class="section no-pad wf-section">
    <div class="w-layout-grid grid-home-about">
      <div data-w-id="e99d9bfb-7308-3dee-80c7-1c1a1925f641" style="opacity:1" class="grid-home-about-left"></div>
      <div id="w-node-a8fe1936-a965-ba6a-86ea-c1c2a6198f53-7a2221ec" data-w-id="a8fe1936-a965-ba6a-86ea-c1c2a6198f53" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="text-align-centre pad-lr-10">
        <h2>We built Surefooted for people like you</h2>
        <p>Our team has been operating a highly successful horse riding holiday company in Wales for decades, while also managing a travel agency selling activity holidays from other operators all over the world, so we have a unique understanding of both sides of the industry and have experienced the pain points first hand.<br>‍<br>We launched Surefooted to change the way that small and medium-sized activity holiday tour operators organise their holiday data and connect them with travel agents to increase their sales.<br><br></p>
        <a href="#" class="button w-inline-block">
          <div class="text-block">Join for free today</div>
        </a>
      </div>
      <div data-w-id="3f509017-8b44-a383-0eaf-476743279b0b" style="opacity:1" class="grid-home-about-right"></div>
    </div>
  </div><img src="img/bg-curve-white.svg" loading="lazy" alt="" class="curve-top">
  <div data-w-id="6d95ddf4-9202-3821-076c-379ddcd10a54" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="section bg-light-grey wf-section">
    <div data-w-id="643cb7a3-3db0-7c5a-d836-bc8a71129f01" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="container skinny text-align-centre pad-lr-60 pad-tb-30 w-container">
      <div class="spacer _30"></div>
      <h2>Easy to set up and designed around multiple use-cases</h2>
      <p>Both activity holiday operators and travel agents selling activity holidays will benefit from Surefooted’s time-saving features to keep holiday information up to date and always ready for the next customer’s online booking.<br></p>
    </div>
    <div class="container skinny w-container">
      <div data-w-id="e45f6d98-d5ac-2fc0-9b74-d72a761be5a7" style="opacity:1" class="w-layout-grid grid-2x2">
        <div id="w-node-e0606c4f-ee52-2447-9e50-272c753e4da5-753e4da5" class="card">
          <div class="flex-vertical-centre flex-justify-space">
            <h5 class="feature-card-heading">Live bookings for Operators &amp; Agents</h5>
            <div class="emoji-icon">💳</div>
          </div>
          <p class="para-small">No more waiting around for emails and chasing confirmations, allow your customers to book their holidays directly on your website with our holiday embed codes.</p>
        </div>
        <div id="w-node-e0606c4f-ee52-2447-9e50-272c753e4da5-753e4da5" class="card">
          <div class="flex-vertical-centre flex-justify-space">
            <h5 class="feature-card-heading">Travel agents can find holidays to sell</h5>
            <div class="emoji-icon">🔎</div>
          </div>
          <p class="para-small">Give your customers more of what they love and expand your network of activity holiday tour operators by searching the directory. Request access and start selling!</p>
        </div>
        <div id="w-node-e0606c4f-ee52-2447-9e50-272c753e4da5-753e4da5" class="card">
          <div class="flex-vertical-centre flex-justify-space">
            <h5 class="feature-card-heading">Multiple Agents can keep availability up to date</h5>
            <div class="emoji-icon">👩🏻‍💻</div>
          </div>
          <p class="para-small">If you’re an Operator who works with a lot of Agents to sell your holidays across the web, make sure you never have a booking clash again with live availability tracking.</p>
        </div>
        <div id="w-node-e0606c4f-ee52-2447-9e50-272c753e4da5-753e4da5" class="card">
          <div class="flex-vertical-centre flex-justify-space">
            <h5 class="feature-card-heading">Operators can manage all of their holidays in one place</h5>
            <div class="emoji-icon">🔑</div>
          </div>
          <p class="para-small">Inputting all your holiday dates, pricing and availability numbers can be so time consuming. Surefooted has a user-friendly dashboard to make this task a breeze.</p>
        </div>
      </div>
      <div class="spacer _60"></div>
    </div>
  </div>
  <div class="section wf-section">
    <div class="container slim pad-tb-30 w-container" style="background-color: white;">
      <div data-w-id="da33a47a-682c-2ae2-d0c1-e23a95b5f558" style="opacity:1;-webkit-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="w-layout-grid grid-2-col">
        <div id="w-node-da33a47a-682c-2ae2-d0c1-e23a95b5f559-7a2221ec" class="flex-vertical centre text-align-centre-mobile">
          <h2>Secure bookings and safe transactions</h2>
          <p>Live bookings payments are processed through a secure Stripe payment form for quick deposit payments from a name that customers know and trust.<br>‍<br>Holiday payments will come directly to you from Surefooted, we just take a small percentage processing fee from every booking made. No more waiting around for payments. <br></p>
            <router-link :to="{ name: 'login', params: {} }"><div class="button-text-only w-inline-block">Get started</div></router-link>
            <div class="button-icon">
              <div class="icon margin-lr-auto w-embed">
                <!--  Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)   -->
               <!-- <svg version="1.1" id="icon_arrow_right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
                  <style type="text/css">
.icon_arrow_right{fill:currentColor;}
</style>
                  <path class="icon_arrow_right" d="M14.7,6.9l-3.4-3.4c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l2.3,2.3H0.7C0.3,6.8,0,7.1,0,7.5v0
c0,0.4,0.3,0.7,0.7,0.7h11.9l-2.3,2.3c-0.3,0.3-0.3,0.7,0,1l0,0c0.3,0.3,0.7,0.3,1,0l3.4-3.3C15.1,7.8,15.1,7.3,14.7,6.9z"></path>
                </svg> -->
              </div>
            </div>
        </div>
        <div class="flex-right"><img src="img/surefooted-and-stripe.svg" loading="lazy" width="393" data-w-id="da33a47a-682c-2ae2-d0c1-e23a95b5f560" alt="" class="flex-right rotate-interaction"></div>
      </div>
    </div>
  </div>
  <div class="section wf-section">
    <div class="cta-wrapper w-container" style="background-color: white;">
      <div class="pad-tb-10"></div>
    </div>
    <div data-w-id="f7ee4643-77c7-e7e8-a4b7-5d6d38186b01" class="container conclusive-cta pad-tb-60 radius-10 pad-lr-20 w-container">
      <div class="container super-skinny text-align-centre">
        <h2 class="white">Get started for free today</h2>
        <p class="white">Sign up for free as an Operator or Agent with Surefooted to start listing your activity holidays and connect with new travel partners.</p>
        <router-link :to="{ name: 'signup', params: {} }"><div class="button white cta-button w-inline-block">Create an account</div></router-link>
      </div>
    </div>
  </div>
  <div class="section wf-section">
    <div class="container w-container" style="background-color: white;">
      <div class="w-layout-grid grid-2-col-1fr-x-3fr">
        <div id="w-node-_8c79606d-de7f-b9d5-20e4-48199258301e-9258301b" class="flex-vertical left text-align-centre-mobile">
          <a href="index.html" aria-current="page" class="logo w-nav-brand w--current"><img src="img/surefooted-logo-full.svg" loading="lazy" alt="" class="logo-embed"></a>
          <p class="para-small pad-tb-20">Simple activity holiday management connecting tour operators and travel agents to holiday data and live bookings, all in one place.</p>
        </div>
        <div id="w-node-_8c79606d-de7f-b9d5-20e4-481992583023-9258301b" class="footer-links">
          <div class="flex-top text-align-centre-mobile">
          <router-link aria-current="page" class="footer-link w--current" :to="{ name: 'home', params: {} }">Home</router-link>
        <router-link class="footer-link" :to="{ name: 'login', params: {} }">Login</router-link>
        <router-link class="footer-link last" :to="{ name: 'signup', params: {} }">Sign Up For Free</router-link>
          </div>
          <div class="flex-bottom text-align-centre-mobile">
            <a href="#" class="footer-link small">Terms</a>
            <a href="#" class="footer-link small">Privacy</a>
            <p class="footer-copyright">© 2022 Surefooted. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div data-w-id="4495afe6-a571-699e-7e29-c403c5037b7d" class="mobile-menu-overlay"></div>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  },
  created () {
  },
  data () {
   return {
 }
}

}
</script>
